<script setup lang="ts">
import LastProjects from '~/components/pages/index/LastProjects.vue';
import VAdvantages from '~/components/pages/index/VAdvantages.vue';
import VFaq from '~/components/pages/index/VFaq.vue';
import VFeedback from '~/components/pages/index/VFeedback.vue';
import VPromo from '~/components/pages/index/VPromo.vue';
import { entities } from '~/enitityHelper';
import BlackFridayBanner from '~/components/promo/black-friday/BlackFridayBanner.vue';

useHead({
  title: entities.project.seo.title,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: entities.project.seo.description,
    },
    {
      hid: 'keywords',
      name: 'keywords',
      content: entities.project.seo.keywords,
    },
  ],
});
</script>

<template>
  <div>
    <v-promo entity="project" />
    <last-projects entity="project" />
    <v-advantages entity="project" />
    <v-feedback entity="project" />
    <v-faq />
    <black-friday-banner />
  </div>
</template>

<style lang="scss" scoped></style>
